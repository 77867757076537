.cls-1 {
    fill: none;
    stroke: #fff;
    stroke-miterlimit: 3.86;
    stroke-width: 13px;
  }

  .cls-2 {
    font-size: 164.05px;
  }

  .cls-2, .cls-3, .cls-4, .cls-5, .cls-6 {
    fill: #fff;
  }

  .cls-2, .cls-3, .cls-6 {
    font-family: Marion-Bold, Marion;
    font-weight: 700;
  }

  .cls-7 {
    letter-spacing: -.03em;
  }

  .cls-3 {
    font-size: 134.96px;
  }

  .cls-4, .cls-5 {
    stroke-width: 0px;
  }

  .cls-5 {
    fill-rule: evenodd;
  }

  .cls-8 {
    letter-spacing: 0em;
  }

  .cls-6 {
    font-size: 176.56px;
  }


  .LogoCls-1, .LogoCls-2 {
    fill: #fff;
    stroke-width: 0px;
  }

  .LogoCls-2 {
    fill-rule: evenodd;
  }