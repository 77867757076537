.SecondaryHeaderAyverdi {
    width: 10px;
    height: 24.6vh;
    flex-shrink: 0;
    background: url(../../assets//img/ayverdi2.png), lightgray 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 54%;
    transform: translate(-50%, -50%);
    z-index: -1;
    mix-blend-mode: luminosity;
}

@media only screen and (max-width: 600px) {
    .Header {
      display: none;
    }
  }