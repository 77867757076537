.InputPropMobile {
    /* //   borderRadius: 0 */
    border-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-family: Poppins;
    color: red;
    width: 85% ;
}
