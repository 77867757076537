.BagisNormalText {
    font-family: Poppins;
    font-size: 18px;
    line-height: 1.5;
    font-style: normal;
    /* font-weight: 700; */
    line-height: normal;
    font-family: ChampTurk;
}

.BagisBoldText {
    font-family: Poppins;
    font-size: 18px;
    line-height: 2.5;  
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: ChampTurk;
}