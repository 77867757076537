.AddressCardTitle {
    color: #0D7C97;
    font-family: Poppins;
    font-size: calc(28px + (24 - 28) * ((100vw - 320px) / (1920 - 320)));;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
}

.AddressCardSecondaryTitle {
    color: #464646;
    font-family: Poppins;
    font-size: calc(24px + (24 - 24) * ((100vw - 320px) / (1920 - 320)));
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
}


.AddressCardValues {
    color: #464646;
    font-family: Poppins;
    font-size: calc(24px + (24 - 24) * ((100vw - 320px) / (1920 - 320)));;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-top: 0;
    margin-bottom: 0;
}