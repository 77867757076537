.CourseSliderContainer {
    background-color: #f8f5f1;
    display: flex;
    height: 30vh;
    width: 100%;
}

.SliderTitle {
    background-color: #48A3BD;
    height: 4.5vh;
    color: white;
    font-family: Poppins;
    font-size: 20px;
    line-height: 1.5;
    font-style: normal;
    line-height: normal;
    font-family: ChampTurk;
    display: flex;
    justify-content: center;
    align-items: center;
}


.CourseSliderCard {
    display: flex;
    color: white;
    height: 30vh;
    width: 100%;
    /* width: 9.5vw; */
    /* width: 25.5vw; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.CourseSliderImage {
    height: 30vh;
    width: 100%;
    /* width: 9vw; */
    /* width: 11.5vw; */
}

.AnnouncementBookCardImage{
    height: 30vh;
    width: 60%;
}


@media only screen and (max-width: 991px) {
    .SliderTitle {
        min-height: 7vh;
        font-size: 18px;
        margin-bottom: 10px;
    }

    .CourseSliderContainer {
        margin-bottom: 10px;
        height: 75vw;
    }

    .CourseSliderCard {
        display: flex;
        color: white;
        height: 75vw;
        width: 100%;
    }

    .CourseSliderImage {
        height: 75vw; 
        width: 100%;
        /* max-width: 100%; */

        /* width: 50vw; */
        margin-bottom: 20px;
    }

    .AnnouncementBookCardImage{
        height: 75vw;
        width: auto;
    }
}