.SecondDonationCard {
    width: 30vw;
    height: 12vh;
    flex-shrink: 0;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    display: flex;
    background: #48A3BD;
    flex-direction: column;
    gap: 5px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
}

.SecondDonationCardText {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 0.95vw;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.SecondDonationCardButton {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.9vw;
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    width: 20vw;
    height: 3.5vh;
    border-radius: 15px;
    background-color: #0D7C97;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}


@media only screen and (max-width: 1430px) {
    .SecondDonationCardText {
        font-size: 16px;
    }

    .SecondDonationCardButton {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1277px) {
    .SecondDonationCardText {
        font-size: 15px;
    }

    .SecondDonationCardButton {
        font-size: 14px;
    }
}

@media only screen and (max-width: 1203px) {
    .SecondDonationCardText {
        font-size: 13px;
    }

    .SecondDonationCardButton {
        font-size: 13px;
        width: 25vw;
    }
}


@media only screen and (max-width: 800px) {
    .SecondDonationCardText {
        font-size: 13px;
    }

    .SecondDonationCardButton {
        font-size: 12px;
        width: 25vw;
        width: 75%;
    }

    .SecondDonationCard {
        width: 100%;
        padding: 0;
    }
}