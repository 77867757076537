.Title {
    color: #464646;
    text-align: justify;
    font-family: Poppins;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: ChampTurk;
}


.Paragraph {
    color: #464646;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: justify;
    font-family: ChampTurk;
    line-height: 1.5;
}

.Subtitle {
    color: #464646;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 1.5;

    font-family: ChampTurk;
}

@media only screen and (max-width: 1200px) {
    .Paragraph {
        font-size: 18px;
    }

    .Subtitle {
        font-size: 18px;
    }
}