.FooterMenu{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: justify;
    flex-direction: column;
    font-family: Poppins;
    color: white;
    height: 100%;
    width: 90%;
    /* background-color: red; */
    border: 1px;
    border-color: #48A3BD;
    border-bottom: 0;
    border-top: 0;
    border-style: dotted;
    
}

.FooterMenuItem{
    height: 20%;
    cursor: pointer;
    display: list-item;
    list-style: inside;
    font-family: Poppins;
    list-style-type: circle;
    /* list-style-type: disc; */
    /* font-size: 14px; */
    /* background-color: red; */
}

.FooterMenuItem:hover{
   text-decoration: underline;
    height: 20%;
    cursor: pointer;
    display: list-item;
    list-style-type: disc;
    /* list-style-type: circle; */
}

@media only screen and (max-width: 1200px) and (min-width: 992px){
    .FooterMenuItem{
        font-size: 14px;
        /* background-color: red; */
    }
}

@media only screen and (max-width: 991px) {
    .FooterMenu{
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        font-family: Poppins;
        color: white;
        height: auto;
        border: 0;
    }
}