.MobileHeader {
    height: 10vh;
    flex-shrink: 0;
    /* background: url("https://s3-alpha-sig.figma.com/img/6d6c/6b7a/72b080c525f06c4baed8a9c17a5cf289?Expires=1701648000&Signature=j9UIy8uPglnEnxR8vJjq4X2MAz5dEAmpYbak9eXWiIoYE5QpGfemD~XP-jU54EL0LZNizu1uUTuiD2RvNsYWM0DToSb00lK5DIMiSisV-B1tvU-LtN3HHyanBD4yOQs3~2ZQxOJW874ay7oa373aNtYchjYlLt7VkvNb-NaPXGGiiu4yv7X5nqi~MroiUbL648EZojmrwC7~TvjTUe~veKKhPS0GQPvf-FaJ~8E3O1XHhxgCV~zLesSNX5jqGAdJ~BD6z2nbMm1djFfP7hbT-njf1muUyMNryjb~T56JTw4qVFCI~6vsbUrpABoGHN7wUzKXpOcXx5JkrrwJ5PzG0Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"); */
    background: url('../../assets/img/desen.png');
    display: flex;
    /* position: fixed; */
    flex-direction: row;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
}

.SecondaryPartHeader {
    background-color: #48A3BD;
    padding-left: 5vw;
    padding-right: 5vw;
    /* height: 7.5vh; */
    min-height: 7.5vh;
    /* height: max(7.5vh) ; */
    /* height: 50px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.AyverdiMobile {
    width: 100px;
    width: 20%;
    height: 10vh;
    flex-shrink: 0;
    background: url(../../assets//img/ayverdi2.png), lightgray 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    position: absolute;
    top: 0%;
    left: 80%;
    /* transform: translate(-50%, -50%); */
    /* z-index: 1; */
    mix-blend-mode: luminosity;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-family: Poppins;
    font-size: 16px;
    color: antiquewhite;
}

@media only screen and (min-width: 993px) {
    .MobileHeaderContainer {
        display: none;
    }
}

@media only screen and (max-width: 600px) {
    .AyverdiMobile {
        font-size: 12px;
    }
}


@media only screen and (max-width: 400px) {
    .AyverdiMobile {
        font-size: 10px;
    }
}