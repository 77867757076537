.ContactPageBody {
    background-color: #F8F5F1;
    /* height: 916px; */
    align-items: stretch;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 80px;
    padding: 5dvw;
    padding-top: 40px;
    min-height: 50vh;
}

.ContactPageThirdCol {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* gap: 15px; */
    padding: 0;
}

@media only screen and (max-width: 600px) {
    .ContactPageThirdCol {

        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        gap: 20px,
    
    }
}