.MobileApps {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.MobileAppsText {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.MobileAppsCard {
    /* width: 1000px; */
    min-height: 5vh;
    flex-shrink: 0;
    border-radius: 5px;
    border: 1px solid #48A3BD;
    background: #0F5868;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
}

.MobileAppCardTitle {
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.MobileAppCardTypeText {
    color: #FFF;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}


@media only screen and (max-width: 1430px) {
    .MobileAppsText {
        font-size: 16px;
    }

    .MobileAppCardTitle {
        font-size: 12px;
    }

    .MobileAppCardTypeText {
        font-size: 12px;
    }
}

@media only screen and (max-width: 600px) {
    .MobileAppsText {
        font-size: 16px;
    }

    .MobileAppCardTitle {
        font-size: 11px;
    }

    .MobileAppCardTypeText {
        font-size: 11px;
    }
}