.Footer {
    padding-right: 5vw;
    padding-left: 5vw;
    min-height: 15.2vh;
    /* height: 15.2vh; */
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    display: flex;
    /* background: url("https://s3-alpha-sig.figma.com/img/6d6c/6b7a/72b080c525f06c4baed8a9c17a5cf289?Expires=1701648000&Signature=j9UIy8uPglnEnxR8vJjq4X2MAz5dEAmpYbak9eXWiIoYE5QpGfemD~XP-jU54EL0LZNizu1uUTuiD2RvNsYWM0DToSb00lK5DIMiSisV-B1tvU-LtN3HHyanBD4yOQs3~2ZQxOJW874ay7oa373aNtYchjYlLt7VkvNb-NaPXGGiiu4yv7X5nqi~MroiUbL648EZojmrwC7~TvjTUe~veKKhPS0GQPvf-FaJ~8E3O1XHhxgCV~zLesSNX5jqGAdJ~BD6z2nbMm1djFfP7hbT-njf1muUyMNryjb~T56JTw4qVFCI~6vsbUrpABoGHN7wUzKXpOcXx5JkrrwJ5PzG0Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"), lightgray 0% 0% / 100px 100px repeat; */
    background: url('../../assets/img/desen.png');
}



.Logo {
    /* width: 150px; */
    height: 15.2vh;
}


.FooterMobilAppContainer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 0;
}

.FooterDonationContainer{
        
    margin-bottom: 0px;
}

.FooterMenuColumn{
}


@media only screen and (max-width: 991px) {
    .Footer {
        padding-top: 3vh;
        padding-bottom: 3vh;
        min-height: 60vh;
    }

    .FooterImageContainer {
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 20px; */
        margin-bottom: 2vh;
    }

    .FooterDonationContainer{
        
        /* margin-bottom: 20px; */
        margin-bottom: 2vh;
    }

    .FooterMobilAppContainer {
        
        display: flex;
        align-items: center;
        justify-content: center;
        /* margin-bottom: 20px; */
        margin-bottom: 2vh;
    }

    .FooterMenuColumn{
        width: 100%;
        /* margin-bottom: 20px; */
        margin-bottom: 2vh;
    }
}