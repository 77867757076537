.CourseSliderContainer {
    background-color: white;
    display: flex;
    height: 30vh;
    width: 100%;
    margin-bottom: 10px;
}

.SliderTitle {
    background-color: #48A3BD;
    height: 4.5vh;
    color: white;
    font-family: Poppins;
    font-size: 20px;
    line-height: 1.5;
    font-style: normal;
    line-height: normal;
    font-family: ChampTurk;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
}


.CourseSliderCard {
    display: flex;
    color: white;
    height: 30vh;
    width: 100%;
    /* width: 9.5vw; */
    /* width: 25.5vw; */
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-bottom: 10px;
}

.CourseSliderImage {
    /* height: 30vh;
    width: 100%; */
    width: 100%;
        object-position: 100%;
    /* width: 9vw; */
    /* width: 11.5vw; */
    margin-bottom: 10px;
}


@media only screen and (max-width: 991px) {
    /* .SliderTitle {
        min-height: 7vh;
        font-size: 18px;
        margin-bottom: 10px;
    } */

    /* .CourseSliderContainer {
        margin-bottom: 10px;
        height: 100vw;
    } */

    /* .CourseSliderCard {
        display: flex;

        color: white;
        height: 45vh;

    } */

    /* .CourseSliderImage {
        height: 45vh;
        width: auto;

        margin-bottom: 20px;
    } */
}