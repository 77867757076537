/*RESET*/
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
html, body {height:100%;}
form {height:100%;}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
/* Custom fonts load */
@font-face {
    font-family: 'ChampturkI';
    src: url('fonts/kubbealtifont.eot');
    src: url('fonts/kubbealtifont.eot?#iefix') format('embedded-opentype'),
    url('fonts/kubbealtifont.woff') format('woff'),
    url('fonts/kubbealtifont.ttf') format('truetype'),
    url('fonts/kubbealtifont.svg#champturkiregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Arabic';
    src: url('fonts/kubbealtifont.eot');
    src: url('fonts/kubbealtifont.eot?#iefix') format('embedded-opentype'),
    url('fonts/kubbealtifont.woff') format('woff'),
    url('fonts/kubbealtifont.ttf') format('truetype'),
    url('fonts/kubbealtifont.svg#arabicmedium') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Champturk';
    src: url('fonts/kubbealtifont.eot');
    src: url('fonts/kubbealtifont.eot?#iefix') format('embedded-opentype'),
    url('fonts/kubbealtifont.woff') format('woff'),
    url('fonts/kubbealtifont.ttf') format('truetype'),
    url('fonts/kubbealtifont.svg#champturkmedium') format('svg');

}




@font-face {
    font-family: 'ChampturkB';
    src: url('fonts/kubbealtifont.eot');
    src: url('fonts/kubbealtifont.eot?#iefix') format('embedded-opentype'),
    url('fonts/kubbealtifont.woff') format('woff'),
    url('fonts/kubbealtifont.ttf') format('truetype'),
    url('fonts/kubbealtifont.svg#champturkbregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'ChampturkBI';
    src: url('fonts/kubbealtifont.eot');
    src: url('fonts/kubbealtifont.eot?#iefix') format('embedded-opentype'),
    url('fonts/kubbealtifont.woff') format('woff'),
    url('fonts/kubbealtifont.ttf') format('truetype'),
    url('fonts/kubbealtifont.svg#champturkbiregular') format('svg');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'champturkiregular';
    src: url('fonts/kubbealtifont.eot');
    src: url('fonts/champturki-webfont.eot?#iefix') format('embedded-opentype'),
    url('fonts/champturki-webfont.woff') format('woff'),
    url('fonts/kubbealtifont.ttf') format('truetype'),
    url('fonts/champturki-webfont.svg#champturkiregular') format('svg');
    font-weight: normal;
    font-style: normal;

}
body {
    line-height: 1;
    font-family: 'Open Sans', sans-serif;
    color:#333;
}
.ChampturkB150 {font-family:ChampturkB;font-weight: bold;color:#bf4c4c;clear:left}
.ChampturkI150 {font-family:ChampturkI;font-style: italic;color:#1E852A;}
.ChampturkI14 {font-family:ChampturkI;font-style: italic;color:#18B495;}
.Champturk14 {font-family:Champturk;}
.ChampturkBI14 {font-family:ChampturkBI;font-weight: bold;font-style: italic;color:#0B7C95;}
.ChampturkBI150 {font-family:ChampturkB;font-weight: bold;font-style: italic;color:#bf4c4c;clear:left;}
.Champturk150 {font-family:ChampturkB;}
.ChampturkB16 {font-family:ChampturkB;font-weight: bold;color:#bf4c4c;clear:left;}
.Arabic18 {font-family:Arabic;direction: rtl}
.Temizle {font-family: Champturk;font-style: normal;color: #000000;font-weight: normal;}

.word-dic-list{
    padding-bottom: 40px;
}
.table-responsive{
    padding-top: 40px;
}
.word-dic-list li{
    list-style: none;
}
.word-dic-list li:first-child {
    padding-bottom: 10px;
    font-size: 90%;
    font-weight: bold;
}
.word-dic-list li:last-child {
    font-size:90%;
    line-height:20px;
}
.panel-input{
    display: flex !important;
    align-items: center;
    flex-wrap:wrap !important;
}
.panel-input .input-group{
    margin-right: 20px;
    margin-bottom: 15px;
}
.word-sum{
    width: 90px !important;
}
.p-30{
    padding-left: 30px;
}
