.Draggable{
    position: absolute;
}

.Keyboard{
    background-color: blanchedalmond;
    width: 50%;
     width: 850px; 
    height: 300%;
    padding: 12px;
    padding-left: 35px;
    padding-right: 35px;

}


.Letter{
    background-color: azure;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.BigLetter{
    background-color: azure;
    width: 100px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}