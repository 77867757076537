.ChampturkB150 {
    font-family: ChampturkB;
    font-weight: bold;
    color: #bf4c4c;
    clear: left;
    /* font-size: 25px; */
    font-size: 18px;
    line-height: normal;
}

.ChampturkI150 {
    font-family: ChampturkI;
    font-style: italic;
    color: #1E852A;
    /* font-size: 18px */
    font-size: 18px;
    line-height: normal;
}

.ChampturkI14 {
    font-family: ChampturkI;
    font-style: italic;
    color: #18B495;
    font-size: 18px;
    line-height: normal;
}

.Champturk14 {
    font-family: Champturk;
    font-size: 18px;
    line-height: normal;
}

.ChampturkBI14 {
    font-family: ChampturkBI;
    font-weight: bold;
    font-style: italic;
    color: #0B7C95;
    font-size: 18px;
    line-height: normal;
}

.ChampturkBI150 {
    font-family: ChampturkB;
    font-weight: bold;
    font-style: italic;
    color: #bf4c4c;
    clear: left;
    font-size: 18px;
    line-height: normal;
}

.Champturk150 {
    font-family: ChampturkB;
    font-size: 18px;
    line-height: normal;
}

.ChampturkB16 {
    font-family: ChampturkB;
    font-weight: bold;
    color: #bf4c4c;
    clear: left;
    font-size: 18px;
    line-height: normal;
}

.ChampturkI16 {
    font-family: ChampturkB;
    font-weight: bold;
    color: #bf4c4c;
    clear: left;
    font-size: 18px;
    line-height: normal;
}

.Arabic18 {
    font-family: Arabic;
    direction: rtl;
    font-size: 18px;
    line-height: normal;
}

.Temizle {
    font-family: Champturk;
    font-style: normal;
    color: #000000;
    font-weight: normal;
    font-size: 18px;
    line-height: normal;
}