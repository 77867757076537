.SecondaryFooter {
    min-height: 4.3vh;
    flex-shrink: 0;
    background: #0F5868;
    padding-right: 5vw;
    padding-left: 5vw;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.SecondaryFooterText {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.75vw;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}