.ImageContainer{
    /* background-color: red; */
    display: flex;
}

.ImageContainer2{
    width: 50%; 
    margin-top: 10 ;
    display:none;
    margin-left:auto;
    margin-right:auto;
}

@media only screen and (max-width: 991px) {
    .ImageContainer{
        display: none;
    }
  }

  
@media only screen and (max-width: 991px) {
    .ImageContainer2{
        display: block;
    }
  }