.Card {
    width: 16vw;
    width: 100%;
    height: 26vh;
    flex-shrink: 0;
    background: #656565;
    display: flex; 
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    /* flex-basis: 350px;  */
}

.DonationCardTitle {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: calc(10px + 1.5vw);
    font-size: 30px;
    font-style: normal;
    font-weight: 900;
    line-height: normal;
    text-align: center;
    display: flex;
    /* margin-bottom: 10px; */
}

.DonationCardText {
    color: #FFF;
    font-family: Poppins;
    font-size: 1vw;
    font-size: 18px;
    font-style: normal;
    font-weight: 275;
    line-height: normal;
    text-align: center;
    /* margin-bottom: 1vh; */
}

.DonationCardButton {
    color: #FFF;
    font-family: Poppins;
    font-size: 0.8vw;
    font-size: calc(16px * ((100vw - 320px) / (1920 - 320)));
    font-size: 16px;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
    width: 13vw;
    height: 3.3vh;
    flex-shrink: 0;
    border-radius: 10px;
    background: #464646;
    justify-content: center;
    align-items: center;
    display: flex;
    cursor: pointer;
}

@media screen and (max-width: 1610px) {

    .DonationCardText {
        font-size: 15px;
    }

    .DonationCardButton {
        font-size: 0.8vw;
        font-size: calc(10px + 0.8vw);
        font-size: 14px;
        /* width: 17vw; */
    }
}


@media screen and (max-width: 1345px) {

    .DonationCardButton {
        font-size: 0.8vw;
        font-size: calc(10px + 0.8vw);
        font-size: 14px;
        width: 16vw;
    }
}

@media screen and (max-width: 1120px) {

    .DonationCardText {
        font-size: 15px;
    }

    .DonationCardButton {
        font-size: 0.8vw;
        font-size: calc(10px + 0.8vw);
        font-size: 12px;
        width: 16vw;
    }
    .Card {
        height: 29vh;
    }
}


@media screen and (max-width: 991px) {
    .Card {
        margin-top: 2vh;
    }

    .DonationCardText {
        color: #FFF;
        font-family: Poppins;
        font-size: 1vw;
        font-size: calc(10px + 1.0vw);
        font-style: normal;
        font-weight: 275;
        line-height: normal;
        text-align: center;
        margin-bottom: 15px;
    }

    .DonationCardButton {
        color: #FFF;
        font-family: Poppins;
        font-size: 0.8vw;
        font-size: calc(10px + 0.8vw);
        font-style: italic;
        font-weight: 500;
        line-height: normal;
        width: 50vw;
        height: 3.3vh;
        flex-shrink: 0;
        border-radius: 10px;
        background: #464646;
        justify-content: center;
        align-items: center;
        display: flex;
        cursor: pointer;
    }
}