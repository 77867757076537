.PaginationItemStyle {
    /* margin-left:5px; */
    color: #0D7C97;
}

.PaginationItemStyle:hover{
    background-color: #48A3BD;
    /* color: #48A3BD; */
    color: antiquewhite;
}


.PaginationItemStyleActive{
    background-color: #0D7C97;
    color: antiquewhite;
}

.PaginationItemStyleActive:hover{
    background-color: #0D7C97;
    color: antiquewhite;
}

.PaginationLinkStyle {
    background-color: #b90000;
    border-radius: 5px;

    /* &:hover": {
        backgroundColor: "#772e2e",
    },

    "&:active": {
        backgroundColor: "#772e2e",
    } */
}