.FixedHeader {
    /* height: 12.5vh; */
    height: 8.5vh;
    flex-shrink: 0;
    /* background: url("https://s3-alpha-sig.figma.com/img/6d6c/6b7a/72b080c525f06c4baed8a9c17a5cf289?Expires=1701648000&Signature=j9UIy8uPglnEnxR8vJjq4X2MAz5dEAmpYbak9eXWiIoYE5QpGfemD~XP-jU54EL0LZNizu1uUTuiD2RvNsYWM0DToSb00lK5DIMiSisV-B1tvU-LtN3HHyanBD4yOQs3~2ZQxOJW874ay7oa373aNtYchjYlLt7VkvNb-NaPXGGiiu4yv7X5nqi~MroiUbL648EZojmrwC7~TvjTUe~veKKhPS0GQPvf-FaJ~8E3O1XHhxgCV~zLesSNX5jqGAdJ~BD6z2nbMm1djFfP7hbT-njf1muUyMNryjb~T56JTw4qVFCI~6vsbUrpABoGHN7wUzKXpOcXx5JkrrwJ5PzG0Q__&Key-Pair-Id=APKAQ4GOSFWCVNEHN3O4"); */
    background: url('../../assets/img/desen.png');
    display: flex;
    /* position: fixed; */
    flex-direction: row;
    align-items: center;
    padding-left: 5vw;
    padding-right: 5vw;
}


.Ayverdi {
    width: 5vw;
    /* width: 40%; */
    height: 8vh;
    z-index: 50;
    flex-shrink: 0;
    background: url(../../assets//img/ayverdi2.png), lightgray 50%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    position: absolute;
    top: 50%;
    left: 33%;
    transform: translate(-50%, -50%);
    /* z-index: 1; */
    mix-blend-mode: luminosity;
    color: white;
    display: flex;
    align-items: end;
    justify-content: center;
    cursor: pointer;
    font-family: Poppins;
    font-size: 11px;
}

.Ayverdi:hover {
   
    color: bisque;
    
}

.ButtonContainer{
    width: 11.5vw;
    height: 5.8vh;
    background-color: #48A3BD;
    flex-direction: row;
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-radius: 15px;
    margin:0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.Text1{
    font-weight: normal;
}


@media only screen and (max-width: 992px) {
    .FixedHeader {
        display: none;
    }  
}