.TabContainer {
    width: 16vw;
    /* height: 42vh; */
    min-height: 42vh;
    flex-shrink: 0;
    background: #656565;;
    display: flex;
    justify-content: center;
    flex-direction: column;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border-radius: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
}

.PageTabsTitle {
    color: #FFF;
    text-align: justify;
    font-family: Poppins;
    /* font-size: 1vw; */
    font-size: calc(10px + 0.5vw);
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.PageTabsSubtitle {
    color: #FFF;
    font-family: Poppins;
    /* font-size: 1vw; */
    font-size: calc(10px + 0.4vw);
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    align-items: center;
    display: flex;
    /* min-height: 3vh; */
    margin-bottom: 3px;
}

.PageTabsSubtitle:hover{
    background-color:#464646;
    border-radius: 5px;
}

@media screen and (max-width: 1610px) {

    .PageTabsTitle {
        font-size: 15px;
    }

    .PageTabsSubtitle {
        font-size: 15px;
    }
}

@media screen and (max-width: 1250px) {

    .PageTabsTitle {
        font-size: 14px;
    }

    .PageTabsSubtitle {
        font-size: 14px;
    }
}

@media screen and (max-width: 991px) {
    .DonationCardText {
        color: #FFF;
        font-family: Poppins;
        font-size: 1vw;
        font-size: calc(10px + 1.0vw);
        font-style: normal;
        font-weight: 275;
        line-height: normal;
        text-align: center;
        margin-bottom: 15px;
    }

    .PageTabsTitle {
        font-size: calc(10px + 0.5vw);
    }

    .PageTabsSubtitle {
        font-size: calc(10px + 0.5vw);
    }
}

@media screen and (max-width: 600px) {
    .TabContainer {
        display: none;
    }
  }