.SliderContainer {
  background-color: white;
  display: flex;
  height: 30vh;
  margin-bottom: 10px;
  /* display: flex; */
}

.SliderTitle {
  background-color: #48A3BD;
  height: 4.5vh;
  color: white;
  font-family: Poppins;
  font-size: 20px;
  line-height: 1.5;
  font-style: normal;
  line-height: normal;
  font-family: ChampTurk;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}


.SlideShowCard {
  display: flex;
  /* background-color: blue; */
  color: white;
  height: 30vh;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.SlideShowImage{
  height: 30vh;
  width: 11.5vw;
  margin-bottom: 10px;
  justify-content: center;
  align-items: center;
  display: flex;
}


@media only screen and (max-width: 991px) {
  .SliderTitle {
    min-height: 7vh;
    font-size: 18px;
    margin-bottom: 10px;
  }

  .SliderContainer {
    margin-bottom: 10px;
    height: 45vh;
  }

  .SlideShowCard {
    display: flex;
    /* background-color: blue; */
    color: white;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .SlideShowImage{
    height: 45vh;
    width: 50vw;
    margin-bottom: 20px;
    justify-content: center;
    align-items: center;
    display: flex;
  }
}



.slick-next
{
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 20px;
    height: 20px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
}