@import url('https://fonts.cdnfonts.com/css/beau-rivage');

.TopHeaderText1 {
    color: #FFF;
    font-family: Poppins;
    font-size: 1.2vw;
    font-size: 22px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
}

.TopHeaderTextItalic {
    color: #FFF;
    font-family: Beau Rivage;
    font-size: 36px;
    font-size: 1.9vw;
    font-size: 30px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.HeaderTextContainer {
    padding-top: 0px;
    padding-bottom: 0px;
    flex-direction: row;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 15px;
}

.TopHeaderTextSecond {
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.HeaderTextContainerSecond {
    flex-direction: row;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
    color: #FFF;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-size: 0.95vw;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer
}

.HeaderTextContainerSecond:hover{
    color: #48A3BD;
}


.TopHeaderContainer{ 
    height: 7vh; 
    background-color: red;
    padding: 0;
}

@media only screen and (max-width: 600px) {
    .TopHeader {
      display: none;
    }
  }