.InputProp {
    /* //   borderRadius: 0 */
    border-radius: 0;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    font-family: Poppins;
    color: red;
    width: 22vw;
}


.InputProp:focus {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    /* border: 2px !important; */
    border-color: #48A3BD;
    outline: none !important;
    outline-offset: none !important;
}

@media only screen and (max-width: 1090px) {
    .InputProp {
        width: 21vw;
    }
}


@media only screen and (max-width: 760px) {
    .InputProp {
        width: 20vw;
    }
}

@media only screen and (max-width: 600px) {
    .InputProp {
        width: 18vw;
    }
}