.SliderMobileContainer {
  background-color: white;
  display: flex;
  height: 30vh;
  margin-bottom: 10px;
  /* display: flex; */
}

.SliderMobileTitle {
  background-color: #48A3BD;
  height: 3vh;
  color: white;
  font-family: Poppins;
  font-size: 20px;
  line-height: 1.5;
  font-style: normal;
  line-height: normal;
  font-family: ChampTurk;
  display: flex;
  justify-content: center;
  align-items: center;
}


.SlideShowMobileCard {
  display: flex;
  /* background-color: blue; */
  color: white;
  height: 30vh;
  width: 9.5vw;
  width: 25.5vw;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-bottom: 10px;
}

.SlideShowMobileImage{
  height: 30vh;
  width: 9vw;
  width: 11.5vw;
  margin-bottom: 10px;
}


@media only screen and (max-width: 991px) {
  .SliderMobileTitle {
    
    margin-bottom: 10px;
  }

  .SliderMobileContainer {
    margin-bottom: 10px;
    height: 50vh;
  }

  .SlideShowMobileCard {
    display: flex;
    /* background-color: blue; */
    color: white;
    height: 50vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .SlideShowMobileImage{
    height: 50vh;
    width: 50vw;
    margin-bottom: 20px;
  }
}