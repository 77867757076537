.HorizontalAdd{
    background-color: rgb(72, 163, 189);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 49%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
}


.HorizontalImage{
    width:49%;
    padding:0;
    border-radius:15px;
}

.HorizontalAdd2{
    background-color: rgb(72, 163, 189);
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 7vh;
    width: 49%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px
}

@media only screen and (max-width: 991px) {
    .HorizontalAdd{
        width: 100%;
    }

    .HorizontalAdd2{
        display: none;
    }

    .HorizontalImage{
        width:100%;
        padding:0;
        border-radius:15px;
        margin-bottom: 10px;
    }
  }