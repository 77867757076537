.PageBody {
    background-color: #F8F5F1;
    /* height: 916px; */
    align-items: stretch;
    flex-shrink: 0;
    justify-content: center;
    flex-direction: column;
    display: flex;
    gap: 80px;
    padding: 5dvw;
    padding-top: 20px;
    min-height: 72vh;
}