.ContactFormTitle {
    color: #464646;
    text-align: justify;
    font-family: Poppins;
    font-size: 22px;
    /* font-size: calc(36px + (24 - 36) * ((100vw - 320px) / (1920 - 320))); */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.ContactFormSubTitle {
    color: #464646;
    text-align: center;
    font-family: Poppins;
    font-size: calc(24px + (24 - 24) * ((100vw - 320px) / (1920 - 320)));;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    color:#0D7C97
}

.MessageBoxColorPart { 
    border-radius: 15px 0px 0px 15px;
    background: #48A3BD;
    min-width: 56px;
    min-height: 56px;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.MessageBoxColorPart2 {
    border-radius: 15px 0px 0px 15px;
    background: #48A3BD;
    min-width: 56px;
    min-height: 221px;
    flex-shrink: 0;
    padding-top: 14px;
    align-items: baseline;
    justify-content: center;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.Input {
    border-radius: 0px 15px 15px 0px;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    outline: none !important;
    outline-offset: none !important;
}

.Input:focus {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    /* border: 2px !important; */
    border-color: #48A3BD;
    outline: none !important;
    outline-offset: none !important;
}

.SelectInput {
    height: 56px;
    border-radius: 15px;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.SelectInput:focus {
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important;
    /* border: 2px !important; */
    border-color: #48A3BD;
    outline: none !important;
    outline-offset: none !important;
}



.ContactFormButton {
    width: 19.5vw;
    width: 60%;
    height: 7.4vh;
    flex-shrink: 0;
    border-radius: 15px;
    background: #48A3BD;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    outline: none !important;
    outline-offset: none !important;
    border-color: #48A3BD;
    font-family: Poppins; 
    font-size: 20px;
}

.ContactFormButton:hover{
    background: #0F5868;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.ContactFormButton:active{
    background: red;
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}

.FormGroup{
    width: 100%;
}