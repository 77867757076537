.PrivacyNormalText {
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    /* font-weight: 700; */
    line-height: normal;
    text-align: justify;
    font-family: ChampTurk;
    line-height: 1.5;
}

.PrivacyBoldText {
    font-family: Poppins;
    font-size: 18px;  
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: ChampTurk;
    line-height: 1.5;
}

.PrivacyTitle {
    color: #464646;
    text-align: justify;
    font-family: Poppins;
    font-size: 25px;
    /* font-size: calc(36px + (24 - 36) * ((100vw - 320px) / (1920 - 320))); */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    font-family: ChampTurk;
    line-height: 1.5;
}